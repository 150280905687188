import React, { useContext, useEffect, useState } from "react";
import styles from "./LearningApp.module.css";
import { IoBackspace } from "react-icons/io5";

import { toast } from "react-toastify";
import UserContext from "../../store/user-context";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
const LearningApp = () => {
  const [learningApp, setLearningApp] = useState({
    url: null,
    type: null,
    difficulty: null,
  });
  const userCtx = useContext(UserContext);
  const httpRequest = useAxios();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const appId = searchParams.get("id");
  const CompleteApp = () => {
    const toastDelay = 5000;
    httpRequest({
      url: urls.complete_app,
      method: "POST",
      data: {
        app_id: appId,
      },
      onSuccess: (response) => {
        // setApplications(response?.data?.category?.apps);
        // toast.update("complete-app", {
        //   render: "Congratulations, App Solved!",
        //   type: "success",
        //   isLoading: false,
        //   autoClose: toastDelay,
        //   closeOnClick: true,
        // });
        // setTimeout(() => {
        //   // setLearningApp({
        //   //  url: null,
        //   // type: null,
        //   // difficulty: null
        //   //        });
        //   // setAppId(null);
        //   // navigate(0);
        // }, toastDelay);
      },
      onFailure: (error) => {
        // toast.update("complete-app", {
        //   render: error?.status?.status_message,
        //   type: "error",
        //   isLoading: false,
        //   autoClose: toastDelay,
        //   closeOnClick: true,
        // });
        // setTimeout(() => {
        //   // setLearningApp({
        //   //     url: null,
        //   // type: null,
        //   // difficulty: null
        //   //      });
        //   // setAppId(null);
        //   // navigate(0);
        // }, toastDelay);
      },
    });
    // toast.loading("Please wait...", {
    //   toastId: "complete-app",
    // });
    userCtx.RemoveApp();
  };
  const LoadApp = () => {
    userCtx.SetLoading(true);
    httpRequest({
      url: urls.get_app + appId + "/",
      method: "GET",
      onSuccess: (response) => {
        userCtx.SetLoading(false);
        setLearningApp({
          url: response.data.url,
          type: response.data.type,
          difficulty: response.data.difficulty,
        });
      },
      onFailure: (error) => {
        toast.error("Check your Internet connection!", {
          autoClose: 5000,
        });
      },
    });
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.origin !== "https://learningapps.org") return;
      if (event?.data?.startsWith("AppReady")) {
        // toast.update("load-app", {
        //   render: "Load Finished",
        //   isLoading: false,
        //   autoClose: 2000,
        //   type: "success",
        // });
      } else if (event?.data?.startsWith("AppSolved")) {
        if (learningApp) CompleteApp();
      }
    };
    if (learningApp.url) {
      // toast.loading("Please wait...", {
      //   toastId: "load-app",
      // });
    }

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [learningApp.url]);
  useEffect(() => {
    LoadApp();
  }, []);

  useEffect(
    () => () => {
      if (!userCtx.learningApp.url) {
        // toast.dismiss("load-app");
        // toast.dismiss("complete-app");
      }
      // setTimeout(() => toast.dismiss("load-app"), 1000);
    },
    [userCtx.learningApp]
  );
  return (
    <React.Fragment>
      <meta
        name="viewport"
        content="width=100vw, minimum-scale=0.78, maximum-scale=1, initial-scale=0.78, user-scalable=0"
      ></meta>
      <iframe
        className={styles.frame}
        allowFullScreen={true}
        webkitallowfullscreen=""
        mozallowfullscreen=""
        src={learningApp.url}
        frameborder="0"
      ></iframe>
      <IoBackspace
        style={{
          position: "fixed",
          top: "0.5rem",
          left: "0.5rem",
          width: "2rem",
          height: "2rem",
          cursor: "pointer",
          color: "#28a78c",
        }}
        onClick={() => {
          navigate(-1);
          userCtx.RemoveApp();
        }}
      />
    </React.Fragment>
  );
};

export default LearningApp;
