export const davilo_logo = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/General/logo.png";
export const media_icon = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/media.png";
export const home_welcome = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/welcome.png";
export const levela1 = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/levela-1.png";
export const levela2 = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/levela-2.png";
export const levelb1 = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/levelb-1.png";
export const levelb2 = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/levelb-1.png";
export const levelc = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/levelc.png";
export const media_header = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/Header.png";
export const videoTutorialIcon = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Home/video-tutorials.png";
export const radio_BremenEins = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/Radios/Bremen%20Eins.jpg";
export const radio_BremenNext = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/Radios/Bremen%20Next.jpg";
export const radio_BremenVier = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/Radios/Bremen%20Vier.jpg";
export const radio_BremenZwei = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/Radios/Bremen%20Zwei.jpg";
export const radio_COSMO = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/Radios/COSMO%20(vom%20WDR).jpg";
export const radio_RadioSchlagerparadies = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/Radios/Radio%20Schlagerparadies.jpg";
export const podcast_Episode00 = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/episode_01.png";
export const podcast_Episode01 = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/episode_02.png";
export const podcast_Episode02 = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Media/episode_03.png";
export const profile_header = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Profile/profile.svg";
export const profile_favorites = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Profile/favorites.png";
export const profile_terms = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Profile/terms.png";
export const profile_about = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Profile/aboutus.png";
export const profile_logout = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Profile/logout.png";
export const profile_suggestion = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Profile/suggestion.svg";
export const Matrix = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/AppMatrix.png";
export const ClozeText = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/ClozeText.png";
export const Crosword = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/CrossWord.png";
export const FillTable = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/FillTable.png";
export const FreeTextInput = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/FreetextInput.png";
export const GroupAssignment = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/GroupAssignment.png";
export const GroupClassification = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/GroupClassification.png";
export const GroupPuzzle = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/GroupPuzzle.png";
export const HangMan = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/Hangman.png";
export const HorseRace = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/HorseRace.png";
export const MarkInTexts = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/MarkInText.png";
export const MatchingPairs = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/MatchingPair.png";
export const MatchingPairsOnImage = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/MatchingPairsOnImage.png";
export const MultipleChoiceQuiz = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/MultipleChoiceQuize.png";
export const NumberLine = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/NumberLineIcon.png";
export const PairingGame = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/PairingGame.png";
export const QuizWithTextInput = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/QuizWithTextInput.png";
export const SimpleOrder = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/SimpleOrder.png";
export const TheMillionAirGame = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/TheMillionareGame.png";
export const WordGrid = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/icons/Apps/WordGrid.png";

export const TutorialVideo = "https://davilo.fra1.cdn.digitaloceanspaces.com/web/video/Davilo-1.mp4";