import React from "react";
const UserContext = React.createContext({
  userData: {
    email: null,
    user_id: null,
    token: null,
    profile: null,
  },
  loading: null,
  selectedModule: {
    id: null,
    title: null,
  },
  learningApp: {
    url: "",
    type: "",
    difficulty: "",
  },
  SetEmail: (email) => {},
  StoreUserData: (data) => {},
  UpdateEnrollData: (data) => {},
  SetLoading: (loading) => {},
  SetSelectedModule: (selectedModuleID) => {},
  Logout: () => {},
  SetApp: (url, type, difficulty) => {},
  RemoveApp: () => {},
});

export default UserContext;
