import React, { useContext } from 'react';
import UserContext from '../../store/user-context';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Progress = ({
    progressValue, color, title, Background, className, id
}) => {
    const userCtx = useContext(UserContext);
    const navigate = useNavigate();
    const OnModuleClickHandler = (event) => {
        userCtx.SetSelectedModule({
            id,
            title
        });
        navigate({
            pathname: 'module',
            search: `?${createSearchParams({
              id,
              title
            })}`,
          })
    }
  return (
        <li className={className} onClick={OnModuleClickHandler}>
            <CircularProgressbarWithChildren
            
            value={progressValue}
            strokeWidth={5}
            styles={buildStyles({
                textColor: "red",
                pathColor: color,
                trailColor: "#ddd",
                strokeLinecap: "butt",
                pathTransitionDuration: 0.15,
            })}>
                {Background && <Background />}
            </CircularProgressbarWithChildren>
            <p>{title}</p>
        </li>
  )
}

export default Progress