import React from 'react'
import styles from './WrapLayout.module.css';
const WrapLayout = ({
    className,
    Item,
    itemProps
}) => {
  return (
    <ul className={`${className} ${styles.container}`}>
        {itemProps?.map((item) => <Item {...item}/>)}
    </ul>
  )
}

export default WrapLayout