import React, { useContext, useEffect } from "react";
import styles from "./ProgressList.module.css";
import Progress from "./Progress";
import HorizontalList from "../UI/HorizontalList";
import UserContext from "../../store/user-context";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import { toast } from "react-toastify";
import { ReactComponent as Vocabulary } from "../../icons/Home/vocabulary.svg";
import { ReactComponent as Grammar } from "../../icons/Home/grammar.svg";
import { ReactComponent as Music } from "../../icons/Home/music.svg";
import { ReactComponent as Video } from "../../icons/Home/video.svg";
import { ReactComponent as Dialog } from "../../icons/Home/dialog.svg";
import { ReactComponent as Geschichte } from "../../icons/Home/geschichte.svg";
import { ReactComponent as Prufung } from "../../icons/Home/exam-progress.svg";
const IconsMap = {
  "Dialoge und Redemittel": Dialog,
  Grammatik: Grammar,
  Lesetexte: Vocabulary,
  Musik: Music,
  Videos: Video,
  Wortschatz: Vocabulary,
  "Deutsche Geschichte": Geschichte,
  "Prüfung": Prufung,
};
const ProgressList = ({ className, currentLevel, onLoadFinished }) => {
  const userCtx = useContext(UserContext);
  const httpRequest = useAxios();
  const LoadCurrentEnroll = (event) => {
    httpRequest({
      url: urls.get_current_enroll,
      method: "POST",
      data: {
        user_id: userCtx.userData.user_id,
      },
      onSuccess: (response) => {
        // toast.update("enroll", {
        //   render: response?.status?.status_message,
        //   type: "success",
        //   isLoading: false,
        //   autoClose: 2000,
        // });
        onLoadFinished();
        userCtx.UpdateEnrollData(response?.data?.current_enroll);
      },
      onFailure: (error) => {
        toast.update("enroll", {
          render: error?.status?.status_message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      },
    });
    // toast.loading("Updating Enroll Info...", {
    //   toastId: "enroll",
    // });
  };
  useEffect(() => {
    LoadCurrentEnroll();
  }, []);
  return (
    <div className={`${className} ${styles.container}`}>
      <p className={styles.title}>Current level {currentLevel}</p>
      <HorizontalList
        Item={Progress}
        stretch={true}
        className={`${styles["list"]}`}
        itemProps={userCtx.userData.profile?.current_enroll?.modules_info?.map(
          (item, index) => ({
            key: index,
            title: item?.module,
            color: item?.color || "#69d736",
            progressValue:
              (item?.NumberOfCompletedModuleApps / item?.NumberOfModuleApps) *
              100,
            Background: item?.background || IconsMap[item?.module],
            className: styles.progress,
            id: item?.id,
          })
        )}
      />
    </div>
  );
};
export default ProgressList;
