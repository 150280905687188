import React from 'react'
import styles from './Level.module.css';
const Level = ({
    className,
    Background,
    title,
    onClick
}) => {
  return (
    <li className={`${className} ${styles.container}`} onClick={onClick}>
        <img src={Background} alt=""/>
        <p>{title}</p>
    </li>
  )
}

export default Level