import React, { useState } from "react";
import ReactPlayer from "react-player";
import { TutorialVideo } from "../static/cdn";


const Tutorial = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "99.3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <ReactPlayer url={TutorialVideo} width="100%" height="100%" controls={true}/>
      
    </div>
  );
};

export default Tutorial;
