import React, { useState, useContext, useEffect } from "react";
import styles from "./Home.module.css";
import UserContext from "../store/user-context";
import { useNavigate } from "react-router-dom";
import HomePageHeader from "../components/Home/HomePageHeader";
import ProgressList from "../components/Home/ProgressList";
import LevelItems from "../components/Home/LevelItems";
// import Media from "../icons/Home/media.png";
import ReactGA from "react-ga4";
import { media_icon, videoTutorialIcon } from "../static/cdn";
const Home = () => {
  const [loading, setLoading] = useState({
    completed_apps_lastweek: false,
    current_enroll: false,
    level_items: false,
  });
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const isWelcomeHeader = userCtx.userData.profile?.current_enroll === null;

  const UpdateLoadingStatus = (key) => {
    setLoading((prevState) => {
      const loadingClone = { ...prevState };
      loadingClone[key] = true;
      return loadingClone;
    });
  };
  useEffect(() => {
    if (Object.values(loading).filter((val) => !val).length === 0) {
      userCtx.SetLoading(false);
    }
  }, [Object.values(loading).filter((val) => !val).length]);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <React.Fragment>
      <div className={`${styles.container} vertical-flex-start`}>
        <HomePageHeader
          isWelcomeHeader={isWelcomeHeader}
          className={`${
            isWelcomeHeader
              ? styles["header__welcome"]
              : styles["header__normal"]
          }`}
          onLoadFinished={() => UpdateLoadingStatus("completed_apps_lastweek")}
        />
        {!isWelcomeHeader && (
          <ProgressList
            className={styles["levels-progress"]}
            currentLevel={userCtx.userData.profile?.current_enroll?.level}
            onLoadFinished={() => UpdateLoadingStatus("current_enroll")}
          />
        )}
        <LevelItems
          className={styles.levels}
          setLoading={setLoading}
          onLoadFinished={() => UpdateLoadingStatus("level_items")}
        />
        <div className={`${styles.cards}`}>
        <div
          className={`${styles.media} ${styles.card}`}
          onClick={() => navigate("/media")}
        >
          <p>Media</p>
          <img src={media_icon} alt="media" />
        </div>
        <div
          className={`${styles.tutorial} ${styles.card}`}
          onClick={() => navigate("/tutorial")}
        >
          <p>Tutorial</p>
          <img src={videoTutorialIcon} alt="media" />
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Home;
