import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./Games.module.css";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import UserContext from "../../store/user-context";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import Modal from "react-modal";
import { toast } from "react-toastify";

import { Matrix } from "../../static/cdn";
import { ClozeText } from "../../static/cdn";
import { Crosword } from "../../static/cdn";
import { FillTable } from "../../static/cdn";
import { FreeTextInput } from "../../static/cdn";
import { GroupAssignment } from "../../static/cdn";
import { GroupClassification } from "../../static/cdn";
import { GroupPuzzle } from "../../static/cdn";
import { HangMan } from "../../static/cdn";
import { HorseRace } from "../../static/cdn";
import { MarkInTexts } from "../../static/cdn";
import { MatchingPairs } from "../../static/cdn";
import { MatchingPairsOnImage } from "../../static/cdn";
import { MultipleChoiceQuiz } from "../../static/cdn";
import { NumberLine } from "../../static/cdn";
import { PairingGame } from "../../static/cdn";
import { QuizWithTextInput } from "../../static/cdn";
import { SimpleOrder } from "../../static/cdn";
import { TheMillionAirGame } from "../../static/cdn";
import { WordGrid } from "../../static/cdn";

const ImageMap = {
  Matrix,
  ClozeText,
  Crosword,
  FillTable,
  FreeTextInput,
  GroupAssignment,
  GroupClassification,
  GroupPuzzle,
  HangMan,
  HorseRace,
  MarkInTexts,
  MatchingPairs,
  MatchingPairsOnImage,
  MultipleChoiceQuiz,
  NumberLine,
  PairingGame,
  QuizWithTextInput,
  SimpleOrder,
  TheMillionAirGame,
  WordGrid,
};

// application template
//   {
//     "id": "cf57784c-91cf-4a6e-a47a-d98a6400af03",
//     "done": false,
//     "url": "https://learningapps.org/watch?v=pdxfwxsan21&js=&script=https://file.eternalbytes.ir/script.js",
//     "description": "Dialog",
//     "difficulty": 5,
//     "type": "SimpleOrder"
// }
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    gap: "5%",
    fontFamily: "Roboto",
    height: "40vh",
    width: "50%",
    borderRadius: "8px",
  },
};
Modal.setAppElement("#root");
const Games = () => {
  const [applications, setApplications] = useState([]);
  const [reportApp, setReportApp] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const userCtx = useContext(UserContext);
  const httpRequest = useAxios();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title");
  const reportRef = useRef();
  const toastDelay = 1500;

  function closeModal() {
    setIsOpen(false);
  }
  const LoadApplications = () => {
    userCtx.SetLoading(true);
    httpRequest({
      url: urls.get_enroll_info,
      method: "POST",
      data: {
        user_id: userCtx.userData.user_id,
        object_type: "Category",
        object_id: searchParams.get("id"),
      },
      onSuccess: (response) => {
        userCtx.SetLoading(false);
        setApplications(response?.data?.category?.apps);
      },
      onFailure: (error) => {},
    });
  };

  const SendReport = (msg) => {
    httpRequest({
      url: urls.report,
      method: "POST",
      data: { description: msg, feedback_type: "1" },
      onSuccess: (response) => {
        toast.update("report-app", {
          render: "Thanks for your report!",
          type: "success",
          isLoading: false,
          autoClose: toastDelay,
          closeOnClick: true,
        });
        closeModal();
      },
      onFailure: (error) => {
        toast.update("report-app", {
          render: "Report Failed!",
          type: "error",
          isLoading: false,
          autoClose: toastDelay,
          closeOnClick: true,
        });
      },
    });
    toast.loading("Please wait...", {
      toastId: "report-app",
    });
  };
  // "https://learningapps.org/watch?v=pdxfwxsan21&js=&script=https://file.eternalbytes.ir/script.js"

  useEffect(() => {
    if (title) LoadApplications();
    else navigate("/home");
  }, []);

  return (
    <div className={`${styles.container} vertical-flex-start`}>
      {applications.length > 0 && <p className={styles.title}>{title}</p>}
      <ul className={`${styles.apps} vertical-flex-start`}>
        {applications.map((app, index) => {
          return (
            <li className={styles.app} key={app?.id}>
              <div>
                <img src={ImageMap[app?.type]} alt="icon" />
                <p
                  onClick={() => {
                    userCtx.SetApp(
                      // app?.url?.split("&")[0],
                      app?.url,
                      app?.type,
                      app?.difficulty
                    );
                    navigate(
                      {
                        pathname: "app",
                        search: `?${createSearchParams({
                          id: app?.id,
                        })}`,
                      },
                      { relative: "path" }
                    );
                  }}
                >{`${app?.description}`}</p>
              </div>
              <div>
                <input type="radio" checked={app?.done} readOnly />
                <button
                  className={styles.report}
                  onClick={() => {
                    const appName = app?.description;
                    setReportApp(appName);
                    setIsOpen(true);
                  }}
                >
                  Report
                </button>
              </div>
            </li>
          );
        })}
      </ul>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // className={syles.modal}
      >
        <p>{`Reporting ${reportApp || title}`}</p>
        <textarea className={styles["modal-message"]} ref={reportRef} />
        <button
          onClick={() => SendReport(reportRef.current?.value)}
          className={styles["modal-button"]}
        >
          Send
        </button>
      </Modal>
    </div>
  );
};

export default Games;
