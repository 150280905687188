import React, { useState, useEffect, useContext } from "react";
import styles from "./Podcast.module.css";
import UserContext from "../../store/user-context";
import PlayerContext from "../../store/player-context";
import HorizontalList from "../UI/HorizontalList";
import VerticalList from "../UI/VerticalList";
import CardTitleAndImage from "../UI/CardTitleAndImage";
import Episode from "./Episode";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";

import { ReactComponent as PodcastSVG } from "../../icons/Media/podcast.svg";
import ReactGA from 'react-ga4';

const Podcast = () => {
  const userCtx = useContext(UserContext);
  const playerCtx = useContext(PlayerContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const httpRequest = useAxios();
  const [data, setData] = useState({
    podcasts: {
      results: [],
      count: 0,
      next: "",
      previous: "",
    },
    episodes: {
      results: [],
      count: 0,
      next: "",
      previous: "",
    },
  });
  const [current, setCurrent] = useState({
    podcast: "",
    episode: "",
  });

  const OnPodcastSelect = (slug) => {
    setCurrent((prevState) => ({ ...prevState, podcast: slug }));
  };

  const OnEpisodePlayButtonClickHandler = (
    stream_url,
    pdf_url,
    episode_name
  ) => {
    playerCtx.SetLinks(stream_url, pdf_url, "Podcast", stream_url);
    playerCtx.SetTitle(episode_name);
    playerCtx.Play();
  };
  const LoadPodcasts = () => {
    userCtx.SetLoading(true);
    httpRequest({
      url: urls.podcasts,
      method: "Get",
      onSuccess: (response) => {
        const result = response?.data?.results?.filter(
          (pod) => pod?.slug !== "slowgerman-3"
        );
        playerCtx.SetPodcasts({
          count: response?.data?.count,
          next: response?.data?.next,
          previous: response?.data?.previous,
          results: result,
        });
        setData((prevState) => ({
          episodes: {
            results: [],
            count: 0,
            next: "",
            previous: "",
          },
          podcasts: {
            count: response?.data?.count,
            next: response?.data?.next,
            previous: response?.data?.previous,
            results: result,
          },
        }));

        if (result?.length > 0) {
          const type = searchParams.get("type");
          if (type) {
            setCurrent((prevState) => ({
              episode: "",
              podcast: type,
            }));
          } else {
            setCurrent((prevState) => ({
              episode: "",
              podcast: result[0]?.slug,
            }));
          }
        }
      },
      onFailure: (error) => {},
    });
  };

  const LoadEpisodes = (url, showLoading) => {
    if (showLoading) userCtx.SetLoading(true);
    url &&
      httpRequest({
        url,
        method: "Get",
        onSuccess: (response) => {
          userCtx.SetLoading(false);

          playerCtx.SetEpisodes({
            count: response?.data?.count,
            next: response?.data?.next,
            previous: response?.data?.previous,
            results: [...data.episodes.results, ...response?.data?.results],
          });

          setData((prevState) => ({
            ...prevState,
            episodes: {
              count: response?.data?.count,
              next: response?.data?.next,
              previous: response?.data?.previous,
              results: [
                ...prevState.episodes.results,
                ...response?.data?.results,
              ],
            },
          }));
        },
        onFailure: (error) => {},
      });
  };
  useEffect(() => {
    if (playerCtx.podcasts.results.length === 0) LoadPodcasts();
    else
      setData((prevState) => ({ podcasts: playerCtx.podcasts, episodes: playerCtx.episodes }));
  }, []);

  useEffect(() => {
    if (current.podcast)
      LoadEpisodes(urls.episodes + current.podcast + "/episodes/", false);
  }, [current.podcast]);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <React.Fragment>
      <HorizontalList
        Item={CardTitleAndImage}
        itemProps={data.podcasts?.results?.map((item, index) => ({
          key: index,
          title: item?.name,
          onClick: () => OnPodcastSelect(item?.slug),
          className: styles.category,
          svg: PodcastSVG,
        }))}
        className={styles.categories}
      />
      {data.episodes.count !== 0 && (
        <VerticalList
          Item={Episode}
          itemProps={data.episodes.results.map((episode, index) => ({
            name: episode?.name,
            className: styles.episode,
            key: index,
            index,
            onClick: () =>
              OnEpisodePlayButtonClickHandler(
                episode?.stream_url,
                episode?.pdf_url,
                episode?.name
              ),
          }))}
          className={styles.episodes}
          title={"Episodes"}
          titleClassName={styles["episodes-title"]}
          onScrollReachesEnd={() => LoadEpisodes(data.episodes.next)}
        />
      )}
    </React.Fragment>
  );
};

export default Podcast;
