import React, { useState, useEffect, useContext } from "react";
import styles from "./Radio.module.css";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import UserContext from "../../store/user-context";
import PlayerContext from "../../store/player-context";
import WrapLayout from "../UI/WrapLayout";
import CardTitleAndImage from "../UI/CardTitleAndImage";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
// import BremenEins from "../../icons/Media/Radios/Bremen Eins.jpg";
// import BremenNext from "../../icons/Media/Radios/Bremen Next.jpg";
// import BremenVier from "../../icons/Media/Radios/Bremen Vier.jpg";
// import BremenZwei from "../../icons/Media/Radios/Bremen Zwei.jpg";
// import COSMO from "../../icons/Media/Radios/COSMO (vom WDR).jpg";
// import RadioSchlagerparadies from "../../icons/Media/Radios/Radio Schlagerparadies.jpg";
import { radio_BremenEins } from "../../static/cdn";
import { radio_BremenNext } from "../../static/cdn";
import { radio_BremenVier } from "../../static/cdn";
import { radio_BremenZwei } from "../../static/cdn";
import { radio_COSMO } from "../../static/cdn";
import { radio_RadioSchlagerparadies } from "../../static/cdn";
import ReactGA from 'react-ga4';

const RadioImages = {
  "Bremen Eins": radio_BremenEins,
  "Bremen Next": radio_BremenNext,
  "Bremen Vier": radio_BremenVier,
  "Bremen Zwei": radio_BremenZwei,
  "COSMO (vom WDR)": radio_COSMO,
  "Radio Schlagerparadies": radio_RadioSchlagerparadies,
};

const Radio = () => {
  const userCtx = useContext(UserContext);
  const playerCtx = useContext(PlayerContext);
  const httpRequest = useAxios();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const radio = searchParams.get("radio");
  const [data, setData] = useState({
    results: [],
    count: 0,
    next: "",
    previous: "",
  });

  const OnRadioClickHandler = (stream_url, slug, name) => {
    // navigate(
    //   {
    //     pathname: "radio",
    //     search: `?${createSearchParams({
    //       radio: slug,
    //     })}`,
    //   },
    //   { relative: "path" }
    // );
    playerCtx.SetTitle(name);
    playerCtx.SetLinks(stream_url, "", "Radio", slug);
    playerCtx.Play();
    ReactGA.event({
      category: 'Radio',
      action: name,
    });
  };
  const LoadRadios = () => {
    userCtx.SetLoading(true);
    httpRequest({
      url: urls.radios,
      method: "Get",
      onSuccess: (response) => {
        userCtx.SetLoading(false);
        const result = response?.data?.results;
        setData((prevState) => ({
          count: response?.data?.count,
          next: response?.data?.next,
          previous: response?.data?.previous,
          results: result,
        }));
        playerCtx.SetRadios(response?.data);
      },
      onFailure: (error) => {},
    });
  };
  useEffect(() => {
    if (playerCtx.radios.results.length === 0) LoadRadios();
    else setData(playerCtx.radios);
  }, []);
  useEffect(() => {
    if (radio) {
      const [targetRadio] = playerCtx.radios.results.filter(
        (rdo) => rdo?.slug === radio
      );
      if (playerCtx.links.type === "Radio" && playerCtx.isPlaying) {
        navigate(
          {
            search: `?${createSearchParams({
              radio: playerCtx.links.slug,
            })}`,
          },
          { relative: "path" }
        );
      }
      else if (targetRadio) {
        playerCtx.SetTitle(targetRadio?.name);
        playerCtx.SetLinks(targetRadio?.stream_url, "Radio");
        setTimeout(() => playerCtx.Play(), 5000);
        toast.loading("Loading Radio ...", {
          toastId: "player",
        });
      }
    }
  }, [data.results]);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <WrapLayout
      className={styles.container}
      Item={CardTitleAndImage}
      itemProps={data.results.map((item, index) => ({
        key: index,
        onClick: () =>
          OnRadioClickHandler(item?.stream_url, item?.slug, item?.name),
        className: `${styles.category} vertical-flex-space-between`,
        img: RadioImages[item?.name],
      }))}
      // className={styles.categories}
    />
  );
};

export default Radio;
