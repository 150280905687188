import React, { useRef, useState, useContext } from "react";
import styles from "./Verification.module.css";
import UserContext from "../../store/user-context";
// import Logo from "../../icons/General/logo.png";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import { toast } from "react-toastify";
import { davilo_logo } from "../../static/cdn";
import { useLocation } from "react-router-dom";
const Verification = ({
  header,
  hideResendButton,
  navigateTo,
  url,
  buttonTitle,
  description,
  inputType,
}) => {
  const codeRef = useRef();
  const navigate = useNavigate();
  const httpRequest = useAxios();
  const userCtx = useContext(UserContext);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const OnVerifyClickHandler = (event) => {
    event.preventDefault();
    const codeValue = codeRef.current.value;
    const emailReg =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (inputType === "CODE" && codeValue === "") {
      toast.error("Code field can't be empty");
    } else if (inputType === "EMAIL" && codeValue === "") {
      toast.error("Email field can't be empty");
    } else if (inputType === "EMAIL" && emailReg.test(codeValue) === false) {
      toast.error("Please enter a valid email address");
    } else {
      toast.dismiss();
      httpRequest({
        url: url,
        method: "POST",
        data:
          inputType === "CODE"
            ? {
                email: userCtx.userData.email,
                code: codeValue,
              }
            : {
                email: codeValue,
              },
        headers: {
          "Content-Type": "application/json",
        },
        onSuccess: onVerifySuccess,
        onFailure: onVerifyFailure,
      });
      toast.loading("Please wait...", {
        toastId: "verification",
      });
      setLoading(true);
    }
  };

  const onVerifySuccess = (response) => {
    toast.update("verification", {
      render: response?.status?.status_message,
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });
    if (inputType === "EMAIL") userCtx.SetEmail(codeRef.current.value.trim());
    else if (inputType === "CODE" && response?.data !== null) {
      userCtx.StoreUserData(response?.data);
    }
    navigate(navigateTo);
  };

  const onVerifyFailure = (error) => {
    toast.update("confirm-email", {
      render: error?.status?.status_message,
      type: "error",
      isLoading: false,
      autoClose: 2000,
    });
    setTimeout(() => setLoading(false), 2000);
  };

  const OnResendClickedHandler = (event) => {
    toast.dismiss();
    httpRequest({
      url: urls.send_confirm_email,
      method: "POST",
      data: {
        email: userCtx.userData.email,
      },
      headers: {
        "Content-Type": "application/json",
      },
      onSuccess: onResendSuccess,
      onFailure: onResendFailure,
    });
    toast.loading("Please wait...", {
      toastId: "verification",
    });
    setLoading(true);
  };

  const onResendSuccess = (response) => {
    toast.update("send-confirm-email", {
      render: response?.status?.status_message,
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });
  };

  const onResendFailure = (error) => {
    toast.update("send-confirm-email", {
      render: error?.status?.status_message,
      type: "error",
      isLoading: false,
      autoClose: 2000,
    });
    setTimeout(() => setLoading(false), 2000);
  };
  console.log(location)
  return (
    <div className={styles.container}>
      <img src={davilo_logo} alt="logo" />
      <p className={styles.login}>{header || ""}</p>
      <p>{description}</p>
      <form className={styles.form}>
        <label className={inputType === "EMAIL" ? styles.email : styles.code}>
          <input
            type={inputType === "CODE" ? "number" : "email"}
            ref={codeRef}
            placeholder={inputType === "EMAIL" ? "Email" : "Confirmation code"}
          />
        </label>
        <button
          type="submit"
          className={styles.submit}
          onClick={OnVerifyClickHandler}
        >
          {buttonTitle}
        </button>
      </form>
      {inputType === "EMAIL" || location.pathname === "/confirm-email" ? (
        <p>
          Dear Davilo Users, If you encounter registration issues or haven’t
          received your confirmation code, please email us at
          Manager@davilo.org. We’ll promptly address your concerns. Also,
          consider checking your spam folder for the code.
        </p>
      ) : (
        <></>
      )}
      {hideResendButton ? (
        <React.Fragment></React.Fragment>
      ) : (
        <div className={styles.resend}>
          <p>Didn't recieve your code?</p>
          <p onClick={OnResendClickedHandler}>Resend</p>
        </div>
      )}
    </div>
  );
};

export default Verification;
