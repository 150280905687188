import React from "react";
import styles from "./Chart.module.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Chart = ({ values, timeSpan }) => {
  const generateLabels = () => {
    const labels = [];
    const today = new Date();

    if (timeSpan === "week") {
      for (let i = 6; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        labels.push(
          date.toLocaleString("en", { weekday: "short" }).toUpperCase()
        );
      }
    } else if (timeSpan === "twoWeeks") {
      for (let i = 13; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        labels.push(date.getDate());
      }
    } else if (timeSpan === "lastMonth") {
      const daysInMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      ).getDate();
      for (let i = daysInMonth - 1; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        labels.push(date.getDate());
      }
    }

    return labels;
  };
  const data = {
    type: "line",
    labels: generateLabels().reverse(),
    datasets: [
      {
        label: "",
        data: values,
        lineTension: 0.4,
        borderColor: "rgba(133, 214, 224, 1)",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return;
          let gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(1, "rgba(133, 214, 224, 1)");
          gradient.addColorStop(0, "rgba(133, 214, 224, 0.1)");
          return gradient;
        },
        fill: true,
        borderWidth: 2,
        pointRadius: 0,
      },
    ],
  };
  const config = {
    responsive: true,
    maintainAspectRatio: false,
    
    plugins: {
      legend: {
        align: "center",
        position: "top",
        labels: {
          boxWidth: 0,
          font: {
            color: "red",
          },
        },
      },
    },

    scales: {
      x: {
        ticks: {
          font: {
            family: "Roboto",
            size: 10,
          },
        },
        grid: {
          lineWidth: "0px",
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          font: {
            family: "Roboto",
            size: 10,
          },
        },
        grid: {
          lineWidth: "0px",
          display: false,
        },
      },
    },
  };
  return (<Line className={styles.container} data={data} options={config} />);
};

export default Chart;
