import React, { useState, useContext, useRef, useEffect } from "react";
import PlayerContext from "../store/player-context";
import styles from "./Player.module.css";
import { MdCancel } from "react-icons/md";
import { MdPauseCircleFilled } from "react-icons/md";
import { MdPlayCircleFilled } from "react-icons/md";
import { ReactComponent as Play } from "../icons/Player/play.svg";
import { ReactComponent as Forward } from "../icons/Player/forward.svg";
import { ReactComponent as Backward } from "../icons/Player/backward.svg";
import { ReactComponent as Exercise } from "../icons/Player/exercise.svg";
import { ReactComponent as Pdf } from "../icons/Player/pdf.svg";
import { Link } from "react-router-dom";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4';

const Player = ({ className }) => {
  const [isVisible, setIsVisible] = useState(true);
  const playerCtx = useContext(PlayerContext);
  const OnCancelClickHandler = () => {
    playerCtx.Pause();
    playerCtx.SetStatus(false);
    setIsVisible(false);
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/player" });
  }, []);
  return (
    <React.Fragment>
      <div
        className={`${styles.container} ${className} ${
          playerCtx.links.type === "Radio" ? styles.radio : ""
        }`}
      >
        <MdCancel
          fill="red"
          className={`${styles["canecel-button"]}`}
          onClick={OnCancelClickHandler}
        />

        {playerCtx.isActive && (
          <AudioPlayer
            autoPlay={true}
            // layout={playerCtx.links.type === "Radio" ? "horizontal" : "stacked"}
            style={{
              // width: playerCtx.links.type === "Radio" ? "80%" : "100%",
              // maxWidth: playerCtx.links.type === "Radio" ? "40rem" : "100%",
              backgroundColor: " #f0fffcea",
              border: "none",
              boxShadow: "none",
            }}
            src={playerCtx.links.stream_url}
            showSkipControls={true}
            onClickNext={() => playerCtx.Next()}
            onClickPrevious={() => playerCtx.Previous()}
            onPlayError={() =>
              toast.error("Play Failed!", {
                autoClose: false,
                toastId: "player",
              })
            }
            onError={() =>
              toast.error("Load Failed!", {
                autoClose: false,
                toastId: "player",
              })
            }
            onPlay={(e) => console.log("onPlay")}
            onLoadStart={() => {
              toast.loading("Loading ...", {
                toastId: "player",
              });
            }}
            onLoadedData={() =>
              toast.update("player", {
                render: "Load successful",
                type: "success",
                isLoading: false,
                autoClose: 2000,
              })
            }
            onSeeked={() =>
              toast.isActive("player") &&
              toast.update("player", {
                render: "Load successful",
                type: "success",
                isLoading: false,
                autoClose: 2000,
              })
            }
            onSeeking={() => {
              toast.loading("Loading ...", {
                toastId: "player",
              });
            }}
            showJumpControls={playerCtx.links.type === "Radio" ? false : true}
            defaultDuration={playerCtx.links.type === "Radio" ? <></> : "--:--"}
            customProgressBarSection={
              playerCtx.links.type === "Radio"
                ? [<div>{playerCtx.title}</div>,]
                : [RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION]
            }
            customAdditionalControls={
              playerCtx.links.type === "Radio"
                ? [RHAP_UI.CURRENT_TIME]
                : [
                    <>
                      {playerCtx.links.pdf_url && (
                        <Link
                          to={playerCtx.links.pdf_url || ""}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Pdf />
                        </Link>
                      )}
                    </>,
                  ]
            }
            customIcons={{
              play: <MdPlayCircleFilled />,
              pause: <MdPauseCircleFilled />,
            }}
            volume={0.2}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default Player;
