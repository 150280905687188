import React from "react";

const CardTitleAndImage = ({
  title: name,
  onClick,
  className,
  svg: SVG,
  img,
}) => {
  return (
    <li
      onClick={onClick}
      className={`${className}`}
      style={{
        borderRadius: "4px",
      }}
    >
      {name && <p>{name}</p>}
      {SVG && <SVG />}
      <div>{img && <img src={img} alt="" />}</div>
    </li>
  );
};

export default CardTitleAndImage;
