import React from "react";
import styles from "./ProfileNav.module.css";
import { Link } from "react-router-dom";
const ProfineNav = ({ title, image: Img, onClick, link }) => {
  return (
    <React.Fragment>
      {onClick ? (
        <li className={styles.container} onClick={onClick}>
          <img src={Img} />
          <p>{title}</p>
        </li>
      ) : (
        <Link
          to={link}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit"
          }}
        >
          <li className={styles.container}>
            <img src={Img} />
            <p>{title}</p>
          </li>
        </Link>
      )}
    </React.Fragment>
  );
};

export default ProfineNav;
