import React, { useRef, useState, useContext } from "react";
import styles from "./Login.module.css";
import UserContext from "../../store/user-context";
// import Logo from "../../icons/General/logo.png";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import { toast } from "react-toastify";
import { davilo_logo } from "../../static/cdn";
const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const httpRequest = useAxios();
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const OnSignInClickHandler = (event) => {
    event.preventDefault();
    const emailValue = emailRef.current.value.trim();
    const passwordValue = passwordRef.current.value.trim();
    const emailReg =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (emailValue === "") {
      toast.error("Email field can't be empty");
    } else if (emailReg.test(emailValue) === false) {
      toast.error("Please enter a valid email address");
    } else if (passwordValue === "") {
      toast.error("Password field can't be empty");
    } else {
      toast.dismiss();
      httpRequest({
        url: urls.sign_in,
        method: "POST",
        data: {
          email: emailValue,
          password: passwordValue,
        },
        headers: {
          "Content-Type": "application/json",
        },
        onSuccess: onSignInSuccess,
        onFailure: onFailure,
      });
      toast.loading("Please wait...", {
        toastId: "sign-in",
      });
      setLoading(true);
    }
  };

  const onSignInSuccess = (response) => {
    toast.update("sign-in", {
      render: response?.status?.status_message,
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });
    userCtx.StoreUserData(response?.data[0]);
    navigate("/home");
  };

  const onFailure = (error) => {
    toast.update("sign-in", {
      render: error?.status?.status_message,
      type: "error",
      isLoading: false,
      autoClose: 2000,
    });
    setTimeout(() => setLoading(false), 2000);
  };
  return (
    <div className={styles.container}>
      <img src={davilo_logo} alt="logo" />
      <p className={styles['login-header']}>Login</p>
      <form className={styles.form}>
        <label className={styles.email}>
          <input type="email" placeholder="Email" required ref={emailRef} />
        </label>
        <label className={styles.password}>
          <input type="password" placeholder="Password" ref={passwordRef} />
        </label>

        <button
          type="submit"
          className={styles.submit}
          onClick={OnSignInClickHandler}
          disabled={loading}
        >
          Login
        </button>
        

      </form>
      <div className={styles.login}>
        <p onClick={() => navigate("/signup")}>Signup</p>
        <p onClick={() => navigate("/send-password-reset-email")}>Forgot your password?</p>
      </div>
    </div>
  );
};

export default Login;
