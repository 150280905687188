import React, { useContext } from "react";
import styles from "./Episode.module.css";
import PlayerContext from "../../store/player-context";
import { MdPlayCircle } from "react-icons/md";
import Lottie from "lottie-react";
import anim from "../../icons/Player/podcast-anim.json";
// import Episode00 from "../../icons/Media/episode_01.png";
// import Episode01 from "../../icons/Media/episode_02.png";
// import Episode02 from "../../icons/Media/episode_03.png";
import { podcast_Episode00 } from "../../static/cdn";
import { podcast_Episode01 } from "../../static/cdn";
import { podcast_Episode02 } from "../../static/cdn";
import ReactGA from 'react-ga4';
const EpisodeImgMap = {
  0: podcast_Episode00,
  1: podcast_Episode01,
  2: podcast_Episode02,
};
const Episode = ({
  className,
  img: Img,
  name,
  onClick,
  description,
  index,
}) => {
  const playerCtx = useContext(PlayerContext);
  return (
    <li
      className={`${className} ${styles.container}`}
      style={{
        borderRadius: "4px",
      }}
    >
      {Img ? (
        <img src={Img} alt="episode" />
      ) : (
        <img src={EpisodeImgMap[index % 3]} alt="episode" />
      )}
      <div className={styles.content}>
        <p className={styles.title}>{name}</p>
        <p className={styles["sub-title"]}>{description}</p>
        {playerCtx.links.episode_index === index ? (
          <Lottie animationData={anim} loop={true} className={styles.play}/>
        ) : (
          <MdPlayCircle className={styles.play} onClick={() => {
            onClick();
            playerCtx.SetActiveEpisode(index);
            ReactGA.event({
              category: 'User',
              action: name,
            });
          }} />
        )}
        <div className={styles.line} />
      </div>
    </li>
  );
};

export default Episode;
