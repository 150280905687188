import React, { useState, useEffect, useRef } from "react";
import styles from "./SelectableHeader.module.css";
import { useLocation } from "react-router-dom";

const SeleactableHeader = ({
  level,
  levels,
  SetLevel,
  title,
  containerClassName,
  pathUpdater,
}) => {
  const itemsRef = useRef([]);
  
  const [initializeClickeHandler, setInitializeClickHandler] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split("/").pop();

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, levels.length);
    const _ = itemsRef.current[level]?.click();
    setInitializeClickHandler(true);
  }, []);
  
  return (
    <React.Fragment>
      {title && <p className={styles.title}>{title}</p>}
      {levels && (
        <ul className={`${styles["level-titles"]} ${containerClassName}`}>
          {levels?.map((item, index) => (
            <li
              ref={(el) => (itemsRef.current[index] = el)}
              key={index}
              style={{
                color: item.color,
              }}
              className={`${styles["level-title"]} ${
                index === level ? styles.selected : styles.unselected
              }`}
              onClick={
                initializeClickeHandler
                  ? (event) => {
                      SetLevel(index);
                      
                    }
                  : (event) => {
                  }
              }
            >
              {item.title}
            </li>
          ))}
          <div
            className={styles.line}
            style={{
              left: itemsRef.current[level]?.offsetLeft,
            }}
          />
        </ul>
      )}
    </React.Fragment>
  );
};

export default SeleactableHeader;
