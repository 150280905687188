import React from 'react'
import styles from './HorizontalList.module.css'
const HorizontalList = ({
    className,
    Item,
    itemProps,
    stretch,
    onScrollReachesEnd
}) => {
  
  return (
    <ul className={`${className} ${styles.container} ${stretch ? styles['container-stretch'] : ''}`} 
    onScroll={(event) => {
      const element = event.target;
      if (element.scrollLeft + element.offsetWidth>= element.scrollWidth && onScrollReachesEnd) onScrollReachesEnd();
    }}
    >
        {itemProps?.map((item) => <Item {...item}/>)}
    </ul>
  )
}

export default HorizontalList