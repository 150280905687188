import React, { useState, useEffect, useContext } from "react";
import styles from "./HomePageHeader.module.css";
import UserContext from "../../store/user-context";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import { toast } from "react-toastify";
import { ReactComponent as Shield } from "../../icons/Home/shield.svg";
import { ReactComponent as User } from "../../icons/Home/profileImage.svg";
import { ReactComponent as Celebration } from "../../icons/Home/celebration.svg";
import Welcome from "../../icons/Home/welcome.png";
const HomePageHeader = ({ isWelcomeHeader, className, onLoadFinished }) => {
  const navigate = useNavigate();
  const [thisWeekCompletion, setThisWeekCompletion] = useState(null);
  const httpRequest = useAxios();
  const userCtx = useContext(UserContext);
  const LoadThisWeekCompletion = (event) => {
    httpRequest({
      url: urls.get_user_progress_chart,
      method: "POST",
      data: {
        user_id: userCtx.userData.user_id,
      },
      onSuccess: (response) => {
        // toast.update("enroll", {
        //   render: response?.status?.status_message,
        //   type: "success",
        //   isLoading: false,
        //   autoClose: 2000,
        // });
        const lastWeekProgress = response?.data?.progress?.progress;
        const sum = lastWeekProgress.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setThisWeekCompletion(sum);
        onLoadFinished();
      },
      onFailure: (error) => {
        toast.update("enroll", {
          render: error?.status?.status_message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      },
    });
    // toast.loading("Updating Enroll Info...", {
    //   toastId: "enroll",
    // });
  };
  useEffect(() => {
    if (thisWeekCompletion === null) LoadThisWeekCompletion();
  }, [thisWeekCompletion])
  return (
    <div
      className={`${styles.container}
    ${className}
    `}
    >
      <User className={styles.user} onClick={() => navigate("/profile")} />
      <Shield className={styles.shield} />
      {isWelcomeHeader ? (
        <React.Fragment>
          <div className={styles["welcome-svg"]}>
            <img src={Welcome} alt="welcome"/>
          </div>
          <div className={styles["welcome"]}>
            <p>Welcome to Davilo</p>
            <p>please choose your level to start</p>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {thisWeekCompletion !== null && <div className={styles["current-week"]}>
            <p>Hello ,</p>
            <p>
              You have completed {thisWeekCompletion} lesson
              {thisWeekCompletion === 1 || thisWeekCompletion === 0 ? "" : "s"}{" "}
              this week
            </p>
          </div>}
          <Celebration className={styles.celebration} />
        </React.Fragment>
      )}
    </div>
  );
};

export default HomePageHeader;
