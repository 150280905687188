import { useState, useEffect, useContext } from "react";
import axios from "axios";
import UserContext from "../store/user-context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const useAxios = () => {
  const [axiosSource, setAxiosSource] = useState(null);
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    setAxiosSource(axiosCancelSource);

    return () => {
      axiosCancelSource.cancel("Request canceled due to component unmount");
    };
  }, []);

  const RedirectToLoginPage = () => {
    navigate("/login");
    userCtx.SetLoading(false);
    // userCtx.StoreUserData({
    //   token: null,
    //   user: {
    //     id: null,
    //     profile: null,
    //     email: null
    //   }
    // })
  };
  const httpRequest = ({
    url,
    method,
    data = null,
    params = null,
    withCredentials = false,
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userCtx.userData.token}`,
    },
    onSuccess = (response) => {},
    onFailure = (error) => {},
  }) => {
    if (method.toUpperCase() === "GET") {
      axios
        .get(url, {
          headers,
          params: params,
          // cancelToken: axiosSource.token // Set cancellation token for GET requests
        })
        .then((response) => onSuccess(response?.data))
        .catch((error) => {
          if (error?.response?.data?.status?.status_code === 401) {
            RedirectToLoginPage();
            return;
          }
          if (error?.code === "ECONNABORTED")
            toast.error("Check your network connectivity!", {
              autoClose: false,
            });
          console.log(error);
          onFailure(error?.response?.data);
        });
    } else if (method.toUpperCase() === "POST") {
      axios
        .post(url, data, {
          headers,
          params,
          // cancelToken: axiosSource.token // Set cancellation token for POST requests
        })
        .then((result) => onSuccess(result?.data))
        .catch((error) => {
          if (error?.response?.data?.status?.status_code === 401) {
            RedirectToLoginPage();
            return;
          }
          if (error?.code === "ECONNABORTED")
            toast.error("Check your network connectivity!", {
              autoClose: false,
            });
          console.log(error);
          onFailure(error?.response?.data);
        });
    }
  };

  return httpRequest;
};
