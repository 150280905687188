import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./static/cdn.css"
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import PlayerProvider from "./store/PlayerProvider";
import UserProvider from "./store/UserProvider";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga4';
// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.initialize('G-KRX0303W9H');
const root = ReactDOM.createRoot(document.getElementById("root"));
Sentry.init({
  // dsn: process.env.REACT_APP_SENTRY_DSN,
  dsn: 'https://bc009f0f81d1ab240aa26567c9468736@o4507160381358080.ingest.us.sentry.io/4507160385683456',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // "localhost",
    "https://app.davilo.org",
  ],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <BrowserRouter>
    <UserProvider>
      <PlayerProvider>
        <App />
      </PlayerProvider>
    </UserProvider>
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
