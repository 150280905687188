import React, { useContext, useEffect, useState } from "react";
import styles from "./Categories.module.css";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,  
} from "react-router-dom";

import UserContext from "../../store/user-context";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";

// category template
// {
//     id: null,
//     category: null,
//     NumberOfCategoryApps: null,
//     NumberOfCompletedCategoryApps: null,
//   }
const Categories = () => {
  const [categories, setCategories] = useState([]);
  const userCtx = useContext(UserContext);
  const httpRequest = useAxios();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title");
  const LoadCategories = () => {
    userCtx.SetLoading(true);
    httpRequest({
      url: urls.get_enroll_info,
      method: "POST",
      data: {
        user_id: userCtx.userData.user_id,
        object_type: "Module",
        object_id: searchParams.get("id"),
      },
      onSuccess: (response) => {
        userCtx.SetLoading(false);
        setCategories(response?.data?.module?.categories);
      },
      onFailure: (error) => {},
    });
  };

  useEffect(() => {
    if (title) LoadCategories();
    else navigate("/home");
  }, []);
  return (
    <div className={`${styles.container} vertical-flex-start`}>
      {categories.length > 0 && <p className={styles.title}>{title}</p>}
      <ul className={`${styles.categories} vertical-flex-start`}>
        {categories.map((category, index) => (
          <li
            className={styles.category}
            key={category?.id}
            onClick={() => {
              navigate({
                pathname: "category",
                search: `?${createSearchParams({
                  id: category?.id,
                  title: category?.category,
                })}`,
              
              }, { relative: "path" });
            }}
          >
            <p>{category?.category}</p>
            <p>
              {category?.NumberOfCompletedCategoryApps}/
              {category?.NumberOfCategoryApps}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Categories;
