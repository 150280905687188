import React, { useState, useRef, useEffect } from "react";
import PlayerContext from "./player-context";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

//"https://webstream.schlagerparadies.de/schlagerparadies128k.mp3"
const PlayerProvider = (props) => {
  const [current, setCurrent] = useState({
    links: {
      stream_url: "",
      pdf_url: null,
      type: "",
      slug: "",
      episode_index: null,
    },
    isActive: false,
    isPlaying: false,
    podcasts: {
      results: [],
      count: 0,
      next: "",
      previous: "",
    },
    episodes: {
      results: [],
      count: 0,
      next: "",
      previous: "",
    },
    radios: {
      results: [],
      count: 0,
      next: "",
      previous: "",
    },
    title: null,
  });
  const SetLinks = (stream_url, pdf_url, type, slug, episode_index) => {
    setCurrent((prevState) => ({
      ...prevState,
      links: {
        stream_url,
        pdf_url,
        type,
        slug: type === "Radio" ? slug : "",
        episode_index: episode_index,
      },
    }));
  };
  const Next = () => {
    if (current.links.type === "Radio") {
      const index = current.radios.results.findIndex(
        (radio) => radio?.slug === current.links.slug
      );
      console.log(index);
      if (index !== -1 && index < current.radios.results.length - 1) {
        SetLinks(
          current.radios.results[index + 1]?.stream_url,
          "",
          "Radio",
          current.radios.results[index + 1]?.slug,
        );
        SetTitle(current.radios.results[index + 1]?.name);
        Play();
      }
    }
    else if (current.links.type === "Podcast") {
      const index = current.episodes.results.findIndex(
        (episodd) => episodd?.stream_url === current.links.stream_url
      );
      if (index !== -1 && index < current.episodes?.results.length - 1) {
        SetLinks(
          current.episodes.results[index + 1]?.stream_url,
          current.episodes.results[index + 1]?.pdf_url,
          "Podcast",
          current.episodes.results[index + 1]?.stream_url,
          index + 1
        );
        SetTitle(current.episodes.results[index + 1]?.name);
        Play();
      }
    }
  };
  const Previous = () => {
    if (current.links.type === "Radio") {
      const index = current.radios.results.findIndex(
        (radio) => radio?.slug === current.links.slug
      );
      if (index !== -1 && index > 0) {
        SetLinks(
          current.radios.results[index - 1]?.stream_url,
          "",
          "Radio",
          current.radios.results[index - 1]?.slug
        );
        SetTitle(current.radios.results[index - 1]?.name);
        Play();
      }
    }
    else if (current.links.type === "Podcast") {
      const index = current.episodes.results.findIndex(
        (episodd) => episodd?.stream_url === current.links.stream_url
      );
      if (index !== -1 && index > 0) {
        SetLinks(
          current.episodes.results[index - 1]?.stream_url,
          current.episodes.results[index - 1]?.pdf_url,
          "Podcast",
          current.episodes.results[index - 1]?.stream_url,
          index - 1
        );
        SetTitle(current.episodes.results[index - 1]?.name);
        Play();
      }
    }
  };
  const Play = () => {
    setCurrent((prevState) => ({
      ...prevState,
      isActive: true,
      isPlaying: true,
    }));
    
  };

  const SetPodcasts = (podcasts) => {
    setCurrent((prevState) => ({ ...prevState, podcasts }));
  };

  const SetEpisodes = (episodes) => {
    setCurrent((prevState) => ({ ...prevState, episodes }));
  };

  const SetRadios = (radios) => {
    setCurrent((prevState) => ({ ...prevState, radios }));
  };
  const Pause = () => {
    setCurrent((prevState) => ({ ...prevState, isPlaying: false }));
  };

  const IsPlaying = () => {
    return current.isActive;
  };

  const SetStatus = (isActive) => {
    setCurrent((prevState) => ({ ...prevState, isActive }));
  };
  const SetTitle = (title) => {
    setCurrent((prevState) => ({ ...prevState, title }));
  };

  const SetActiveEpisode = (index) => {
    setCurrent(prevState => ({
      ...prevState,
      links: {
        ...prevState.links,
        episode_index: index,
      }
    }))
  }
  const value = {
    ...current,
    SetLinks,
    Play,
    Pause,
    IsPlaying,
    SetStatus,
    SetPodcasts,
    SetEpisodes,
    SetTitle,
    SetRadios,
    Next,
    Previous,
    SetActiveEpisode,
  };

  // useEffect(() => () => {
  //   if (!current.isActive) toast.dismiss("player");
  // }, [current.isActive])
  Sentry.setContext("player-store", current);
  return (
    <PlayerContext.Provider value={value}>
      {/* {current.links.stream_url && (
        <ReactHowler
          src={current.links.stream_url}
          playing={current.isPlaying}
          onPlay={() =>{

            // setCurrent(prevState => ({ ...prevState, isPlaying: true}))
            toast.update("player", {
              render: "Load Successfully",
              type: "success",
              isLoading: false,
              autoClose: 2000,
              closeOnClick: true,
            })
          }}
          html5={true}
          volume={0.5}
          onLoadError={() =>
            toast.update("player", {
              render: "Load Failed!",
              type: "error",
              isLoading: false,
              autoClose: 2000,
              closeOnClick: true,
            })
          }
          onPlayError={() =>
            toast.update("player", {
              render: "Play Failed!",
              type: "error",
              isLoading: false,
              autoClose: 2000,
              closeOnClick: true,
            })
          }
          
          ref={howlerRef}
        />
      )} */}
      {props?.children}
    </PlayerContext.Provider>
  );
};

export default PlayerProvider;
