import { ReactComponent as Vocabulary } from '../../icons/Home/vocabulary.svg';
import { ReactComponent as Grammar } from '../../icons/Home/grammar.svg';
import { ReactComponent as Music } from '../../icons/Home/music.svg';
import { ReactComponent as Times } from '../../icons/Home/times.svg';
import { ReactComponent as Dialog } from '../../icons/Home/dialog.svg';

import { levela1 } from '../../static/cdn';
import { levela2 } from '../../static/cdn';
import { levelb1 } from '../../static/cdn';
import { levelb2 } from '../../static/cdn';
import { levelc } from '../../static/cdn';
export const progressLevelData = [
    {
        title: "Wortschatz",
        progress: 20,
        background: Vocabulary,
        color: "#69d736",
    },
    {
        title: "Grammatik",
        progress: 60,
        background: Grammar,
        color: "#36b9d7",
    },
    {
        title: "Musik",
        progress: 90,
        background: Music,
        color: "#d76436",
    },
    {
        title: "Uhrzeiten",
        progress: 80,
        background: Times,
        color: "#d736c1",
    },
    {
        title: "Dialogue",
        progress: 40,
        background: Dialog,
        color: "red",
    },
];



export const ReArrangeData = ( fetchedLevels ) => {
    const levels = [
        {
            title: "Level A",
            color: "inherit",
            levels: [
                
            ],
        },
        {
            title: "Level B",
            color: "#feaa5d",
            levels: [
                
            ],
        },
        {
            title: "Level C",
            color: "#363b64",
            levels: [
                
            ],
        },
    ];
    const _ = fetchedLevels?.forEach((level, index) => {
        if (level?.name?.startsWith("A")) {
            levels[0].levels.push({
                ...level,
                background: index === 0 || index === 1 ? levela1 : levela2
            });
        }
        else if (level?.name?.startsWith("B")) {
            levels[1].levels.push({
                ...level,
                background: index === 4 || index === 5 || index === 6 ? levelb1 : levelb2
            });
        }
        else if (level?.name?.startsWith("C")) {
            levels[2].levels.push({
                ...level,
                background: levelc
            });
        }
    });
    return levels;
}