import React, { useState, useEffect, useContext, useRef } from "react";
import UserContext from "../store/user-context";
import styles from "./Profile.module.css";
import ProfineNav from "../components/Profile/ProfineNav";
import Modal from "react-modal";
import { profile_header } from "../static/cdn";
import { profile_favorites } from "../static/cdn";
import { profile_about } from "../static/cdn";
import { profile_terms } from "../static/cdn";
import { profile_logout } from "../static/cdn";
import { profile_suggestion } from "../static/cdn";
import Chart from "../components/Profile/Chart";
import SeleactableHeader from "../components/UI/SeleactableHeader";
import { useAxios } from "../hooks/useAxios";
import { urls } from "../static/url";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4';

const levels = [
  {
    title: "Last Week",
  },
  {
    title: "Two Weeks",
  },
  {
    title: "Last Month",
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    gap: "5%",
    fontFamily: "Roboto",
    height: "40vh",
    width: "50%",
    borderRadius: "8px",
  },
};


const Profile = () => {
  const [currentChart, setCurrentChart] = useState(0);
  const [values, setValues] = useState([]);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const httpRequest = useAxios();
  const userCtx = useContext(UserContext);
  const suggestionRef = useRef();
  const toastDelay = 1500;

  const nav = [
    {
      title: "About us",
      image: profile_about,
      link: "https://davilo.org/",
    },
    {
      title: "Favorites",
      image: profile_favorites,
      link: "https://davilo.org/",
    },
    {
      title: "Terms",
      image: profile_terms,
      link: "https://davilo.org/terms-en.html",
    },
    {
      title: "Suggestions",
      image: profile_terms,
      onClick: () => setShowSuggestionModal(true),
    },
    {
      title: "Log Out",
      image: profile_logout,
      onClick: userCtx.Logout
    },
  ];

  const onSuccess = (response) => {
    // toast.update("chart-loading", {
    //   render: response?.status?.status_message,
    //   type: "success",
    //   isLoading: false,
    //   autoClose: 2000,
    // });
    setValues(response?.data?.progress?.progress?.reverse());
  };

  const onFailure = (error) => {
    // toast.update("chart-loading", {
    //   render: error?.status?.status_message,
    //   type: "error",
    //   isLoading: false,
    //   autoClose: 2000,
    // });
  };
  const LoadChartData = (status) => {
    httpRequest({
      url: urls.get_user_progress_chart,
      method: "POST",
      data: {
        user_id: userCtx.userData.user_id,
        days: status === 0 ? 7 : status === 1 ? 14 : 30,
      },

      onSuccess: onSuccess,
      onFailure: onFailure,
    });
    // toast.loading("Please wait...", {
    //   toastId: "chart-loading",
    // });
  };

  const SendSuggestion = (msg) => {
    httpRequest({
      url: urls.report,
      method: "POST",
      data: { description: msg, feedback_type: "2" },
      onSuccess: (response) => {
        toast.update("report-app", {
          render: "Thanks for your Suggestion!",
          type: "success",
          isLoading: false,
          autoClose: toastDelay,
          closeOnClick: true,
        });
        setShowSuggestionModal(false);
      },
      onFailure: (error) => {
        toast.update("report-app", {
          render: "Suggestion Failed!",
          type: "error",
          isLoading: false,
          autoClose: toastDelay,
          closeOnClick: true,
        });
      },
    });
    toast.loading("Please wait...", {
      toastId: "report-app",
    });
  };

  useEffect(() => {
    LoadChartData(currentChart);
    return () => toast.dismiss("chart-loading");
  }, [currentChart]);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={profile_header} alt="profile header" />
      </div>
      <div className={styles["chart-container"]}>
        <SeleactableHeader
          level={currentChart}
          levels={levels}
          SetLevel={setCurrentChart}
          containerClassName={styles["selectable-header-ul"]}
        />
        <div className={styles.chart}>
          <Chart
            values={values}
            timeSpan={
              values.length === 7
                ? "week"
                : values.length === 14
                ? "twoWeeks"
                : "lastMonth"
            }
          />
        </div>
      </div>
      <ul className={styles.nav}>
        {nav.map((item, index) => (
          <ProfineNav {...item} key={index}/>
        ))}
      </ul>
      <Modal
        isOpen={showSuggestionModal}
        onRequestClose={() => setShowSuggestionModal(false)}
        style={customStyles}
        // className={syles.modal}
      >
        <p>{`Suggestion`}</p>
        <textarea className={styles["modal-message"]} ref={suggestionRef}/>
        <button
          onClick={() => SendSuggestion(suggestionRef.current?.value)}
          className={styles["modal-button"]}
        >
          Send
        </button>
      </Modal>
    </div>
  );
};

export default Profile;
