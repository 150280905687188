import React, { useState, useEffect } from "react";
import styles from "./VerticalList.module.css";
const VerticalList = ({ className, Item, itemProps, onScrollReachesEnd }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading) setLoading(false);
  }, [itemProps]);
  return (
    <ul
      className={`${styles.container} ${className}`}
      onScroll={(event) => {
        const element = event.target;
        if (
          element.scrollTop + element.offsetHeight >=
            element.scrollHeight * 0.8 &&
          onScrollReachesEnd &&
          !loading
        ) {
          onScrollReachesEnd();
          setLoading(true);
        }
      }}
    >
      {itemProps?.map((item) => (
        <Item {...item} />
      ))}
    </ul>
  );
};

export default VerticalList;
