import React, { useReducer, useEffect, useContext } from "react";
import ReactLoading from "react-loading";
import UserContext from "../../store/user-context";

const Loading = ({ type, loading }) => {
  
    return (
        <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
        }}
      >
        <ReactLoading
          type={type || "spinningBubbles"}
          width="10vw"
          height="10vh"
          color="#009688FF"
        />
      </div> 
    );
};

export default Loading;
