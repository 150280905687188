import { ReactComponent as Podcast } from "../../icons/Media/podcast.svg";
import Episode  from "../../icons/Media/episode_01.png";
import { ReactComponent as Radio0 } from "../../icons/Media/radio0.svg";
import { ReactComponent as Radio1 } from "../../icons/Media/radio1.svg";
import { ReactComponent as Radio2 } from "../../icons/Media/radio2.svg";
export const medias = [
    {
        title: "Podcast",
        color: "inherit",
        category: [
            {
                title: "Slowgerman",
                img: Podcast,
                episodeTitle: "Episodes",
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                    {
                        title: "Good Bye, Lenin!",
                        img: Episode,
                    },
                ],
            },
            {
                title: "Hitler",
                episodeTitle: "Episodes",
                img: Podcast,
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                ],
            },
            {
                title: "Hitler",
                episodeTitle: "Episodes",
                img: Podcast,
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                ],
            },
            {
                title: "Hitler",
                episodeTitle: "Episodes",
                img: Podcast,
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                ],
            },
            {
                title: "Hitler",
                episodeTitle: "Episodes",
                img: Podcast,
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                ],
            },
            {
                title: "Hitler",
                episodeTitle: "Episodes",
                img: Podcast,
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                ],
            },
            {
                title: "Hitler",
                episodeTitle: "Episodes",
                img: Podcast,
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                ],
            },
            {
                title: "Hitler",
                episodeTitle: "Episodes",
                img: Podcast,
                hasEpisodes: true,
                episodes: [
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                    {
                        title: "Test",
                        img: Episode,
                    },
                ],
            },
        ],
    },
    {
        title: "Radios",
        color: "inherit",
        category: [
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio0,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio1,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio2,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio0,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio1,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio2,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio0,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio1,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio2,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio0,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio1,
            },
            {
                title: "1111111",
                hasEpisodes: false,
                img: Radio2,
            },
        ],
        
    },
    
];