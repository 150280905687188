import React, { useEffect } from "react";
import styles from "./Media.module.css";
// import Header from "../icons/Media/Header.png";
import SeleactableHeader from "../components/UI/SeleactableHeader";
import { medias } from "../components/Media/Static";
import Podcast from "../components/Media/Podcast";
import Radio from "../components/Media/Radio";
import { useNavigate, useLocation } from "react-router-dom";
import { media_header } from "../static/cdn";
const Media = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split("/").pop();
  const typeUpdater = (index) => {
    
    switch (index) {
      case 0:
        navigate("/media/podcast");
        break;
      case 1:
        navigate("/media/radio");
        break;
      default:
        break;
    }
  };
  const typeToLevel = (path) => {
    if (path === "podcast") return 0;
    else if (path === "radio") return 1;
  };
  // useEffect(() => typeToLevel());
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={media_header} alt="Header" />
      </div>
      <SeleactableHeader
        level={typeToLevel(path)}
        SetLevel={typeUpdater}
        levels={medias}
        containerClassName={styles["selectable-header-ul"]}
        pathUpdater={typeToLevel}
      />
      {path === "podcast" ? <Podcast /> : path === "radio" ? <Radio /> : <></>}
    </div>
  );
};

export default Media;
