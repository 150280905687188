import React from "react";
const PlayerContext = React.createContext({
  links: {
    stream_url: null,
    pdf_url: null,
    type: "",
    slug: "",
    episode_index: null,
  },
  isActive: false,
  isPlaying: false,
  howler: null,
  podcasts: {
    results: [],
    count: 0,
    next: "",
    previous: "",
  },
  episodes: {
    results: [],
    count: 0,
    next: "",
    previous: "",
  },
  radios: {
    results: [],
    count: 0,
    next: "",
    previous: "",
  },
  title: "",
  SetEpisodes: (episodes) => {},
  SetPodcasts: (podcasts) => {},
  SetLinks: (stream_url, pdf_url, type, slug) => {},
  Play: () => {},
  Pause: () => {},
  IsPlaying: () => {},
  SetStatus: (isActive) => {},
  SetTitle: (title) => {},
  SetRadios: (radios) => {},
  Next: () => {},
  Previous: () => {},
  SetActiveEpisode: (index) => {},
});

export default PlayerContext;
