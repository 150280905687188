import React, { useState, useEffect } from "react";
import UserContext from "./user-context";
import Cookies from "js-cookie";
import Loading from "../components/UI/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

const UserProvider = (props) => {
  const navigate = useNavigate();
  const [loadingFromStorage, setLoadingFromStorage] = useState(true);
  const [triggerSyncCookie, setTriggerSyncCookie] = useState(false);
  const [current, setCurrent] = useState({
    userData: {
      email: null,
      token: null,
      user_id: null,
      profile: {
        first_name: "",
        last_name: "",
        age: 0,
        gender: "",
        current_enroll: {
          id: "",
          level: "",
          degree: "",
          description: "",
          modules_info: [],
        },
      },
    },
    loading: true,
    selectedModule: {
      id: null,
      title: null,
    },
    learningApp: {
      url: "",
      type: "",
      difficulty: "",
    },
  });

  const Logout = () => {
    setCurrent((prevState) => ({
      userData: {
        email: null,
        token: null,
        user_id: null,
        profile: {
          first_name: "",
          last_name: "",
          age: 0,
          gender: "",
          current_enroll: {
            id: "",
            level: "",
            degree: "",
            description: "",
            modules_info: [],
          },
        },
      },
      loading: false,
      selectedModule: {
        id: null,
        title: null,
      },
      learningApp: {
        url: "",
        type: "",
        difficulty: "",
      },
    }));
    setTriggerSyncCookie(true);
    navigate("/login");
  };

  const SetEmail = (email) => {
    setCurrent((prevState) => ({
      ...prevState,
      userData: {
        ...prevState.userData,
        email: email,
      },
    }));
  };

  const StoreUserData = (data) => {
    setCurrent((prevState) => ({
      ...prevState,
      userData: {
        token: data?.token,
        user_id: data?.user?.id,
        profile: data?.user?.profile,
        email: data?.user?.email,
      },
    }));
    setTriggerSyncCookie(true);
  };

  const UpdateEnrollData = (data) => {
    const profile = { ...current.userData.profile };
    profile.current_enroll = data;
    setCurrent((prevState) => ({
      ...prevState,
      userData: {
        ...prevState?.userData,
        profile,
      },
    }));
    setTriggerSyncCookie(true);
  };

  const SetLoading = (loading) => {
    setCurrent((prevState) => ({ ...prevState, loading }));
  };

  const SetSelectedModule = (selectedModule) => {
    setCurrent((prevState) => ({ ...prevState, selectedModule }));
  };

  const SetApp = (url, type, difficulty) => {
    setCurrent((prevState) => ({
      ...prevState,
      learningApp: {
        url,
        type,
        difficulty,
      },
    }));
    setTriggerSyncCookie(true);
  };
  const RemoveApp = () => {
    setCurrent((prevState) => ({
      ...prevState,
      learningApp: {
        url: "",
        type: "",
        difficulty: "",
      },
    }));
    setTriggerSyncCookie(true);
  };

  const value = {
    ...current,
    SetEmail,
    StoreUserData,
    UpdateEnrollData,
    SetLoading,
    SetSelectedModule,
    Logout,
    SetApp,
    RemoveApp,
  };

  // Save data to Cookie
  useEffect(() => {
    if (triggerSyncCookie) {
      Cookies.set("userData", JSON.stringify(current.userData));
      Cookies.set("learningApp", JSON.stringify(current.learningApp));
      setTriggerSyncCookie(false);
    }
  }, [triggerSyncCookie]);

  // Load data from Cookie
  useEffect(() => {
    const userData = Cookies.get("userData");
    const learningApp = Cookies.get("learningApp");
    if (userData) {
      const parsedData = JSON.parse(userData);
      if (parsedData?.token) {
        setCurrent((prevState) => ({ ...prevState, userData: parsedData }));
      }
    }
    if (learningApp && learningApp !== undefined) {
      const parsedData = JSON.parse(learningApp);
      if (parsedData?.url) {
        setCurrent((prevState) => ({ ...prevState, learningApp: parsedData }));
      }
    }
    setCurrent((prevState) => ({ ...prevState, loading: false }));
    setLoadingFromStorage(false);
  }, []);
  Sentry.setContext("user-store", {
    learningApp: current.learningApp,
    current_enroll: current.userData.profile.current_enroll,
    email: current.userData.email,
    user_id: current.userData.user_id,
    selectedModule: current.selectedModule,
  });
  return (
    <UserContext.Provider value={value}>
      {loadingFromStorage ? <Loading /> : props?.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
