import React, { useRef, useState, useContext } from "react";
import UserContext from "../../store/user-context";
import styles from "./Signup.module.css";
// import Logo from "../../icons/General/logo.png";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import { toast } from "react-toastify";
import { davilo_logo } from "../../static/cdn";
const Signup = () => {
  const emailRef = useRef();
  const password1Ref = useRef();
  const password2Ref = useRef();
  const navigate = useNavigate();
  const httpRequest = useAxios();
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const OnSignUpClickHandler = (event) => {
    event.preventDefault();
    const emailValue = emailRef.current.value.trim();
    const password1Value = password1Ref.current.value.trim();
    const password2Value = password2Ref.current.value.trim();
    const emailReg =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (emailValue === "") {
      toast.error("Email field can't be empty");
    } else if (emailReg.test(emailValue) === false) {
      toast.error("Please enter a valid email address");
    } else if (password1Value !== password2Value) {
      toast.error("Passwords didn't match");
    } else {
      toast.dismiss();
      httpRequest({
        url: urls.sign_up,
        method: "POST",
        data: {
          email: emailValue,
          password: password1Value,
          profile: {
            first_name: "davilo",
            last_name: "davilo",
            age: 4,
            gender: "M",
          },
        },
        headers: {
          "Content-Type": "application/json",
        },
        onSuccess: onSignUpSuccess,
        onFailure: onFailure,
      });
      toast.loading("Please wait...", {
        toastId: "sign-up",
      });
      setLoading(true);
    }
  };

  const onSignUpSuccess = (response) => {
    toast.update("sign-up", {
      render: response?.status?.status_message,
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });
    userCtx.SetEmail(emailRef.current.value.trim());
    navigate("/confirm-email");
  };

  const onFailure = (error) => {
    toast.update("sign-up", {
      render: error?.status?.status_message,
      type: "error",
      isLoading: false,
      autoClose: 2000,
    });
    setTimeout(() => setLoading(false), 2000);
  };

  return (
    <div className={styles.container}>
      <img src={davilo_logo} alt="logo" />
      <p className={styles.signup}>Sign Up</p>
      <form className={styles.form}>
        <label className={styles.email}>
          <input type="email" placeholder="Email" required ref={emailRef} />
        </label>
        <label className={styles.password}>
          <input type="password" placeholder="Password" ref={password1Ref} />
        </label>
        <label className={styles.password}>
          <input
            type="password"
            placeholder="Repeat Password"
            ref={password2Ref}
          />
        </label>

        <button
          type="submit"
          className={styles.submit}
          onClick={OnSignUpClickHandler}
          disabled={loading}
        >
          Sign Up
        </button>
      </form>
      <div className={styles.login}>
        <p>Already have an account?</p>
        <p onClick={() => navigate("/login")}>Login</p>
      </div>
    </div>
  );
};

export default Signup;
