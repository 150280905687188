import React, { useContext, useEffect } from "react";
import PlayerContext from "./store/player-context";
import UserContext from "./store/user-context";
import "./App.css";
import { urls } from "./static/url";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Media from "./pages/Media";
import Player from "./pages/Player";
import Profile from "./pages/Profile";
import Login from "./pages/login-signup/Login";
import Signup from "./pages/login-signup/Signup";
import Verification from "./pages/login-signup/Verification";
import ResetPassword from "./pages/login-signup/ResetPassword";
import Tutorial from "./pages/Tutorial";
import Categories from "./components/Home/Categories";
import Games from "./components/Home/Games";
import LearningApp from "./components/Home/LearningApp";
import { Bounce, ToastContainer } from "react-toastify";
import Loading from "./components/UI/Loading";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";

function App() {
  const playerCtx = useContext(PlayerContext);
  const userCtx = useContext(UserContext);
  

  useEffect(() => {
    const userData = Cookies.get("userData");
    const learningApp = Cookies.get("learningApp");
    if (userData) {
      const parsedData = JSON.parse(userData);
      // console.log(parsedData)
      Sentry.setContext("cookies", {
        "learning-app": learningApp,
        user: {
          email: parsedData?.email,
          user_id: parsedData?.user_id,
        },
        current_enroll: {
          id: parsedData?.profile?.current_enroll?.id,
          level: parsedData?.profile?.current_enroll?.level,
          degree: parsedData?.profile?.current_enroll?.degree,
        },
      });
    }
  }, []);
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/confirm-email"
          element={
            <Verification
              navigateTo="/login"
              header="Confirmation"
              buttonTitle="CONFIRM"
              description="Enter your verification code sent to your Email address"
              inputType="CODE"
              url={urls.confirm_email}
            />
          }
        />
        <Route
          path="/send-password-reset-email"
          element={
            <Verification
              navigateTo="/confirm-password-reset-code"
              header="Forget Password"
              buttonTitle="SUBMIT"
              description=""
              inputType="EMAIL"
              url={urls.send_password_reset_email}
            />
          }
        />
        <Route
          path="/confirm-password-reset-code"
          element={
            <Verification
              navigateTo="/reset-password"
              header="Confirmation"
              buttonTitle="CONFIRM"
              description="Enter your verification code sent to your Email address"
              inputType="CODE"
              url={urls.confirm_password_reset_code}
            />
          }
        />
        <Route path="/home" element={<Home />} />
        <Route path="/home/module" element={<Categories />} />
        <Route path="/home/module/category" element={<Games />} />
        <Route path="/home/module/category/app" element={<LearningApp />} />
        <Route
          path="/media"
          element={<Navigate to="/media/podcast" replace />}
        />
        <Route path="/media/*" element={<Media />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
      <Player className={`${playerCtx.isActive ? "show" : "hide"}`} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
      {userCtx.loading && <Loading />}
    </React.Fragment>
  );
}

export default App;
