import React, { useState, useEffect, useContext } from "react";
import styles from "./LevelItems.module.css";
import UserContext from "../../store/user-context";
import HorizontalList from "../UI/HorizontalList";
import Level from "./Level";
import SeleactableHeader from "../UI/SeleactableHeader";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import { toast } from "react-toastify";
import { ReArrangeData } from "./Static";

const LevelItems = ({ className, setLoading, onLoadFinished }) => {
  const [level, SetLevel] = useState(0);
  const [levels, setLevels] = useState([]);
  const httpRequest = useAxios();
  const userCtx = useContext(UserContext);

  const onSignInSuccess = (response) => {
    if (response?.status?.success && response?.status?.status_code === 200) {
      const levels = response?.data?.levels;
      setLevels(ReArrangeData(levels));
      onLoadFinished();
    }
  };

  const onFailure = (error) => {};
  useEffect(() => {
    httpRequest({
      url: urls.get_enroll_info,
      method: "POST",
      data: {
        user_id: userCtx.userData.user_id,
        object_type: "Levels",
      },

      onSuccess: onSignInSuccess,
      onFailure: onFailure,
    });
  }, []);
  const OnLevelClicked = (levelId, levelName) => {
    httpRequest({
      url: urls.set_enroll,
      method: "POST",
      data: {
        user_id: userCtx.userData.user_id,
        level_id: levelId,
      },

      onSuccess: (response) => {
        // toast.update("sign-in", {
        //   render: `Level ${levelName} set as current Enroll`,
        //   type: "success",
        //   isLoading: false,
        //   autoClose: 2000,
        // });
        userCtx.UpdateEnrollData(response?.data?.enroll);
      },
      onFailure: (error) => {
        // toast.update("sign-in", {
        //   render: error?.status?.status_message,
        //   type: "error",
        //   isLoading: false,
        //   autoClose: 2000,
        // });
        // setTimeout(() => setLoading(false), 2000);
      },
    });
    // toast.loading("Please wait...", {
    //   toastId: "sign-in",
    // });
  };

  useEffect(() => {
    if (userCtx.userData.profile?.current_enroll?.level?.startsWith("A")) SetLevel(0);
    else if (userCtx.userData.profile?.current_enroll?.level?.startsWith("B")) SetLevel(1);
    else if (userCtx.userData.profile?.current_enroll?.level?.startsWith("C")) SetLevel(2);
  }, [userCtx.userData.profile?.current_enroll?.level])
  return (
    <div className={`${className} ${styles.container}`}>
      {levels.length > 0 && <SeleactableHeader
        level={level}
        levels={levels}
        SetLevel={SetLevel}
        title="Levels"
      />}
      <HorizontalList
        Item={Level}
        itemProps={levels[level]?.levels?.map((item, index) => ({
          key: index,
          title: item?.name,
          Background: item.background,
          className: styles.level,
          onClick: OnLevelClicked.bind(this,item?.id, item?.name),
        }))}
        className={styles.list}
      />
    </div>
  );
};

export default LevelItems;
