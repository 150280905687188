const baseUrl = "https://api.davilo.org/";
const apps = {
    users: "v1/users/",
    app: "v2/apps/",
    dictionary: "v1/dictionary/",
    podcasts: "v1/podcasts/",
    feedback: "v1/feedback/",
};
export const urls = {
    sign_up: baseUrl + apps.users + "sign-up/",
    sign_in: baseUrl + apps.users + "sign-in/",
    user_profile: baseUrl + apps.users + "user-profile/",
    confirm_email: baseUrl + apps.users + "confirm-email/",
    send_confirm_email: baseUrl + apps.users + "send-confirmaton-email/",
    send_password_reset_email: baseUrl + apps.users + "send-password-reset-email/",
    confirm_password_reset_code: baseUrl + apps.users + "confirm-password-reset-code/",
    reset_password: baseUrl + apps.users + "reset-password/",
    refresh_token: baseUrl + apps.users + "refresh-token/",
    unsubscribe_newsletter: baseUrl + apps.users + "unsubscribe-newsletter/",
    unsubscribe_newsletter_result: baseUrl + apps.users + "unsubscribe-newsletter/result/",
    delete_account_request: baseUrl + apps.users + "delete-account/request/",
    delete_account_confirm: baseUrl + apps.users + "delete-account/confirm/",
    get_enroll_info: baseUrl + apps.app + "get-enroll-info/",
    set_enroll: baseUrl + apps.app + "set-enroll/",
    get_current_enroll: baseUrl + apps.app + "get-current-enroll/",
    get_app: baseUrl + apps.app + "applications/",
    complete_app: baseUrl + apps.app + "complete-app/",
    get_user_progress_chart: baseUrl + apps.app + "get-user-progress-chart/",
    podcasts: baseUrl + apps.podcasts + "podcasts/",
    episodes: baseUrl + apps.podcasts + "podcast/",
    radios: baseUrl + apps.podcasts + "radios/",
    report: baseUrl + apps.feedback,
}