import React, { useRef, useState, useContext } from "react";
import styles from "./ResetPassword.module.css";
import UserContext from "../../store/user-context";
// import Logo from "../../icons/General/logo.png";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { urls } from "../../static/url";
import { toast } from "react-toastify";
import { davilo_logo } from "../../static/cdn";
const ResetPassword = () => {
  const password1Ref = useRef();
  const password2Ref = useRef();
  const navigate = useNavigate();
  const httpRequest = useAxios();
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const OnResetPasswordClickHandler = (event) => {
    event.preventDefault();
    const password1Value = password1Ref.current.value.trim();
    const password2Value = password2Ref.current.value.trim();
    
    if (password1Value === "" || password2Value === "") {
      toast.error("Password field can't be empty");
    } else if (password1Value !== password2Value) {
      toast.error("Passwords didn't match");
    } else {
      toast.dismiss();
      httpRequest({
        url: urls.reset_password,
        method: "POST",
        data: {
          user_id: userCtx.userData.user_id,
          new_password1: password1Value,
          new_password2: password2Value,
        },
        onSuccess: onResetPasswordSuccess,
        onFailure: onResetPasswordFailure,
      });
      toast.loading("Please wait...", {
        toastId: "reset-password",
      });
      setLoading(true);
    }
  };

  const onResetPasswordSuccess = (response) => {
    toast.update("reset-password", {
      render: response?.status?.status_message,
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });
    navigate("/home");
  };

  const onResetPasswordFailure = (error) => {
    toast.update("reset-password", {
      render: error?.status?.status_message,
      type: "error",
      isLoading: false,
      autoClose: 2000,
    });
    setTimeout(() => setLoading(false), 2000);
  };
  return (
    <div className={styles.container}>
      <img src={davilo_logo} alt="logo" />
      <p className={styles.signup}>Reset Password</p>
      <form className={styles.form}>
        <label className={styles.code}>
          <input type="password" placeholder="New Password" ref={password1Ref} />
        </label>
        <label className={styles.code}>
          <input
            type="password"
            placeholder="Repeat Password"
            ref={password2Ref}
          />
        </label>

        <button
          type="submit"
          className={styles.submit}
          onClick={OnResetPasswordClickHandler}
          disabled={loading}
        >
          RESET PASSWORD
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
